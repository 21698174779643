$primaryGradientColor: linear-gradient(135deg, rgba(140, 198, 63, 1) 0%, rgba(57, 181, 74, 1) 100%);
$primaryGradientColor280deg: linear-gradient(280deg, rgba(140, 198, 63, 1) 0%, rgba(57, 181, 74, 1) 100%);

$primaryColor: #39b54a;

$extratimeBg: rgba(247, 147, 30, 0.7);
$extratimeBgfocus: rgba(247, 147, 30, 0.24);

$primaryGrey: #7c86a2;
$grey: #7c86a23d;
$lightGrey: #7c8ea239;
$greyMinor: #515769a6;
$secondGrey: #7c8ea21f;
$opacityRow: rgba(197, 202, 206, 0.15);
$scrollbarColor: rgb(193, 193, 193);
$hoverColor: #80bdff;
$blueSoft: #e2e7fa;

$boldText: #3b4149;
$subText: #606060;
$greyText: #c5cace;
$placeholder: #4d4d4d;
$extratimeText: #f7931e;

$borderColor: #d7ddea;

$red: #ff0000;
$redError: #f45151;
$yellowError: #f7931e;

$primaryButton: #29abe2;
$secondaryButton: #cccccc;
$disabledButton: #8080804d;

$shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
$shadowDarkest: 0 3px 6px -4px rgba(134, 26, 26, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
$white: #ffffff;
$focusShadow: 0 0 2px 0.1rem rgba(0, 123, 255, 0.12);
