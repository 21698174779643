.pm-table {
    margin-top: 40px;
}
.pm-table-row {
    cursor: pointer;
}
.sorticon {
    display: inline-block;
}
.ant-table-column-sorter-full {
    margin-top: -0.4em !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgb(236,236,236);
}
.ant-table-column-sorter-full {
    margin-top: -0.4em !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgb(236,236,236);
}
