
.pm-menu{
  text-transform: uppercase;
  background: #0FBCaa;
  color: white !important;;
  font-weight: bold;
  font-size: .8em;
  margin-top: 5px;
  letter-spacing: 2px;
  height: 60px;
  vertical-align: middle !important;
}
.nav-item{
  padding-left: 5px;
}
.pm-menu-current{
  background: #FFBC0F;
}
.pm-menu:link { text-decoration: none}
.pm-menu:visited { text-decoration: none}
.pm-menu:hover { text-decoration: none; color: black; background: #46cce1;}
.pm-menu:active { text-decoration: none}
.pm-submenu:hover{
  background: #FFBC0F!important;;
}
