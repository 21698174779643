@import "./color.scss";

.ts-header-bar {
  height: 65px;
}

.container-fluid {
  padding-bottom: 0;
}

.site-title {
  height: 35px;
  min-width: 85px;
  background-image: url("../src/assets/logo@4x.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
}

.site-title > a:hover {
  text-decoration: none;
}
.layout-content {
  margin-top: 15px;
}

.nav-bar {
  .navlink {
    padding: 0 8px;
    &:hover {
      a {
        transform: scale(1.2);
      }
    }
    a {
      opacity: 0.72;
      line-height: 1;
      color: #3b4149;
      transition: all 0.3s linear;
      cursor: pointer;
      &.active {
        opacity: 1;
        font-weight: 700;
      }
    }
    &.home {
      font-size: 24px;
      a {
        display: flex;
      }
    }
  }
}
