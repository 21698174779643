@import "../../color.scss";
.user-menu-container {
  min-width: 200px;
  background-color: $white;
  border-radius: 4px;
  -webkit-box-shadow: $shadowDarkest;
  box-shadow: $shadowDarkest;
  .user-name {
    margin: 8px 0 0;
    font-weight: 700;
    color: $boldText;
  }
  .user-mail {
    margin: 0;
    font-weight: 300;
    letter-spacing: -0.3px;
    line-height: 1;
    font-size: 12px;
    color: $boldText;
  }
  .ant-divider {
    margin: 12px 0 0;
  }
  .btn-borderless {
    width: 100%;
    height: 100%;
    color: $boldText;
    padding: 12px 0;
    font-size: 14px;
    border-radius: 0;
    border: none;
    &:hover {
      background-color: $secondGrey;
    }
    .anticon {
      padding-right: 4px;
    }
    &.btn-google-account {
      padding: 4px 0;
      margin: 8px 0;
    }
    &.btn-logout {
      margin-top: -1px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .weekie-version {
    text-align: center;
    font-weight: lighter;
    color: $boldText;
    font-size: 0.8em;
    border-radius: 10px;
  }
  .ant-avatar {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.dropdown-user-menu {
  transition: all 200ms ease-in;
  &:hover,
  &.ant-dropdown-open {
    transition: all 200ms ease-in;
    transform: scale(1.2);
  }
}
